<template>
  <!-- <div id="app"> -->
  <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view> -->
  <router-view :key="$route.fullPath" v-if="isRouterActive" />
  <!-- <glFooter></glFooter> -->
  <!-- </div> -->
</template>

<script>
// import isMobileFn from '@/utils/libs/isMobile'
export default {
  name: 'App',
  data() {
    return {
      isRouterActive: true,
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleUserAgentChange);
    document.removeEventListener("visibilitychange", this.handleUserAgentChange);
  },
  mounted() {
    window.addEventListener("resize", this.handleUserAgentChange);
    document.addEventListener("visibilitychange", this.handleUserAgentChange);
    // that.$store.commit('SETISMOBILE',isMobileFn())
    // window.onresize = function () {
    //   that.$store.commit('SETISMOBILE',isMobileFn())
    // }
  },
  methods: {
    handleUserAgentChange() {
      this.$store.commit('updateUserAgent')
    },
    reload() {
      this.isRouterActive = false
      this.$nextTick(() => {
        this.isRouterActive = true
      })
    },
  },
}
</script>

<style>
/* @import "./public/css/element-style-reset.scss"; */
/* body {
  font-family: Arboria;
} */
#app {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f7fa;
}
</style>
