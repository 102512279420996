
const zh = {
	title: {
		home: '首頁',
	},
	nav: {
		desc: '關於',
		desc1: '願景',
		desc2: '服務',
		desc3: '核心產品',
		desc4: '隱私政策',
		desc5: '選擇語言',
		desc6: '',
		desc7: '',
		desc8: '',
		desc9: '',
		desc10: '',
	},
	home: {
		desc: 'SkyDAO Trust總部設於香港，以全球信託法制度為依托，業務遍布全球的Web3信託平台。',
		desc1: 'SkyDAO Trust由資深金融從業人士和區塊鏈Web3元老級團隊攜手共建，引入區塊鏈、AI算法等前沿科技，精心打造的混合金融(HyFi)信託資產管理平台。在SkyDAO Trust，用戶只需擁有一個信託超級賬戶，就能夠通過智能合約錢包，輕鬆投資全球的主要法幣資產及虛擬金融資產，並能享受一站式的資產配置、風險追蹤和調倉管理等全面的財富管理服務。',
		desc2: '穩妥的制度依托',
		desc3: '透過普通法下信託架構，靈活適配不同國家和地區的合規要求，並借助香港對虛擬金融交易的開放性政策，讓不同法域的投資用戶均可無障礙享受面向全球的資產配置。',
		desc4: '堅實的底層技術',
		desc5: 'Web2、Web3雙管齊下，大數據和AI算法加持，並在加密金融投資領域採用Defi、Cefi相結合的HyFi運作模式，最大化加密金融的優勢，實現投資的安全、透明、公平。',
		desc6: '廣泛的資產涵蓋',
		desc7: '覆蓋遍布全球的多種類別資產，包括股票、期貨、期權、債券、基金、保險、加密貨幣等，投資用戶僅需一個超級帳戶就能夠輕鬆觸達全球資產，靈活全面地進行資產組合配置。',
		desc8: '讓資產配置更豐富',
		desc9: '讓投資決策更高效',
		desc10: '讓資產流動更自由',
		desc11: '讓投資使用者更安心',
		desc12: '讓資產交易更公平',
		desc13: '讓資產安全領先全球',
		desc14: '6大服務板塊',
		desc15: '為全球使用者提供全方位信託服務',
		desc16: '家族辦公室信託',
		desc17: '迪拜、倫敦、意大利、加拿大、香港家族辦公室依託，提供家族辦公室運營模板',
		desc18: '穩定幣資產管理信託',
		desc19: '算法穩定幣托底資產管理，為安全投資設下終極保障',
		desc20: '第三方數字錢包支付通道服務',
		desc21: '支持用戶間直接的P2P交易，資產只在用戶手中，真正自己把握自己',
		desc22: '第三方機構資產信託管理服務',
		desc23: 'SkyDAO Trust Web3戰略合作夥伴，為投資者帶來基於Defi-Cefi的更多投資組合',
		desc24: '信用卡資產信託',
		desc25: '支持與法幣互通的信用卡交易，真正打通加密金融與傳統金融的壁壘',
		desc26: 'XTM全球資產信託管理平台',
		desc27: 'SkyDAO Trust官方金融平台',
		desc28: '國家',
		desc29: '交易所',
		desc30: '交易品種',
		desc31: '交易系統',
		desc32: '傳統金融TradFi',
		desc33: '鏈上去中心化金融DeFi',
		desc34: '核心產品',
		desc35: 'SkyDAO混合金融資產信託管理平台',
		desc36: 'HyFi一站管理',
		desc37: 'TradiFi',
		desc38: '傳統金融資產連接整合',
		desc39: '傳統金融資產',
		desc40: '傳統金融工具',
		desc41: 'Crypto',
		desc42: '去中心化全球數字交易',
		desc43: '數字金融資產',
		desc44: '去中心化金融技術',
		desc45: '鏈上資產去中心化管理',
		desc46: '為鏈上資產安全保駕護航',
		desc47: '安全',
		desc48: '用戶在以太坊區塊鏈上使用自己的智能合約錢包保持安全和匿名。用戶擁有自己的私鑰，可以完全控制他們在平台中的資產。',
		desc49: '透明',
		desc50: '使用者的智能合約帳戶在區塊鏈（例如Etherscan.io網站）上清晰可見。與集中式交易所不同，交易者的存款不會集中存放在交易所控制的一個或幾個大型錢包中。',
		desc51: '保障',
		desc52: '用戶的資金完全透明，保障每筆交易的償付能力，並在區塊鏈上執行結算，結算後的資金保留在用戶帳戶中。',
		desc53: 'SkyDAO虛擬信用卡',
		desc54: '一卡在手，縱享Fiat與Crypto的無縫對接',
		desc55: '靈活便捷',
		desc56: '靈活使用多元資產',
		desc57: '在平台上方便還款',
		desc58: '全球通行',
		desc59: '隨時隨地提取現金',
		desc60: '靈活運用平台上多元資產',
		desc61: '安全放心',
		desc62: '2FA身份驗證，生物密碼加持',
		desc63: '多重認證保障用卡安全',
		desc64: '投資者輕鬆接入，全球金融市場運籌帷幄',
		desc65: '',
		desc66: '',
		desc67: '',
		desc68: '',
		desc69: '',
	},
	download: {
		desc: '開啟您的數字金融自由之旅',
		desc1: '中心化與去中心化的雙重金融體驗',
		desc2: '隨時隨地管理您的資產',
		desc3: 'SKYDAO APP下載',
		desc4: 'Trust錢包是為用戶提供便利、安全的數字資產管理和金融服務的核心工具。它依託於專業的中心化機構，結合先進的技術和合規體系，幫助用戶有效率地管理資金、享受信用卡服務，以及跨境支付等功能。',
		desc5: '信用卡服務',
		desc6: '支援全球VISA支付通道，提供數字資產消費和提現服務。',
		desc7: '資產託管',
		desc8: '專業機構級託管服務，確保資金安全。',
		desc9: '快速支付',
		desc10: '綁定銀行卡，實現法幣儲值與提現。',
		desc11: '安全性',
		desc12: '專業團隊運營，採用先進的技術手段保護資金安全。',
		desc13: '加密貨幣蓬勃的發展，許多人透過加密貨幣投資取得了不俗的收益。但加密貨幣跟我們的生活還是缺乏一座可連結的橋樑，而SKYDAO Card解決了這個問題，讓加密貨幣在我們的生活中體現更大的價值。',
		desc14: 'SKYDAO Card，無論是線上購物、線下消費、提款或轉賬，都能快速完成。擁有一張SKYDAO Card，開啟便捷的加密貨幣的金融之旅。',
		desc15: 'SKYDAO萌芽卡',
		desc16: 'SKYDAO黑卡',
		desc17: 'SKYDAO光明卡',
		desc18: 'Web3钱包',
		desc19: 'SKYDAO Web3錢包是去中心化多鏈錢包，簡單、安全和便捷，一鍵即可創建錢包，摒棄複雜的操作流程。提供兌換代幣、資產增值，並可參與去中心化金融(DeFi)生態中，無論是質押、借貸、交易或投資，Web3錢包都為您提供了無縫的操作體驗和豐富的功能選擇。',
		desc20: '非託管錢包',
		desc21: '私鑰由您掌控，真正實現對資產的完全控制。',
		desc22: '多鏈支援',
		desc23: '支持BTC、ETH、BSC等主流公鏈，輕鬆跨鏈交易。',
		desc24: '一鍵進入DeFi應用，參與質押、借貸、收益農場等操作。',
		desc25: '隱私保護',
		desc26: '完全匿名，無需身份認證即可使用。',
		desc27: '為什麼選擇SKYDAO',
		desc28: '信用卡業務',
		desc29: '全球支付隨心而動',
		desc30: '使用加密資產輕鬆綁定虛擬信用卡。',
		desc31: '消費記錄即時更新，支援全球VISA支付網路。',
		desc32: '資產託管',
		desc33: '安全是我們首要任務',
		desc34: '機構級安全託管，提供保障金和保險服務。',
		desc35: '嚴格的多重簽名和冷儲存技術。',
		desc36: '去中心化自由',
		desc37: '掌控您的資產',
		desc38: '無需信任第三方，私鑰完全由使用者掌控。',
		desc39: '即時存取全球DeFi生態。',
		desc40: '即將上線',
		desc41: 'APP 下載',
		desc42: '',
		desc43: '',
		desc44: '',
		desc45: '',
		desc46: '',
		desc47: '',
		desc48: '',
		desc49: '',
	},
	policy: {
		desc: '',
		desc1: '',
		desc2: '',
		desc3: '',
		desc4: '',
		desc5: '',
		desc6: '',
		desc7: '',
		desc8: '',
		desc9: '',
		desc10: '',
		desc11: '',
		desc12: '',
		desc13: '',
		desc14: '',
		desc15: '',
		desc16: '',
		desc17: '',
		desc18: '',
		desc19: '',
		desc20: '',
		desc21: '',
		desc22: '',
		desc23: '',
		desc24: '',
		desc25: '',
		desc26: '',
		desc27: '',
		desc28: '',
		desc29: '',
		desc30: '',
		desc31: '',
		desc32: '',
		desc33: '',
		desc34: '',
		desc35: '',
		desc36: '',
		desc37: '',
		desc38: '',
		desc39: '',
		desc40: '',
		desc41: '',
		desc42: '',
		desc43: '',
		desc44: '',
		desc45: '',
		desc46: '',
		desc47: '',
		desc48: '',
		desc49: '',
	}
};
export default zh;