import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"

export default createStore({
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage
  // })],
  state: {
    isNavShow: false,
    isMobile: false,
    activeIndex: 0,
    lang: 'en_US',
    userAgent: navigator.userAgent || navigator.vendor || window.opera,
    androidApkLink: 'https://scrypto.s3.ap-northeast-1.amazonaws.com/download/android/skydao.apk',
    appStoreLink: 'https://apps.apple.com/hk/app/skydao/id6499490160',
    testFlightLink: 'https://testflight.apple.com/join/lfPm8pM0',
  },
  mutations: {
    updateUserAgent(state) {
      setTimeout(() => {
        state.userAgent = navigator.userAgent || navigator.vendor || window.opera;
      }, 100);
    },
    SETISMOBILE(state, val) {
      state.isMobile = val
    },
    SETNAVSHOW(state, val) {
      state.isNavShow = val
    },
    SETACTIVEINDEX(state, val) {
      state.activeIndex = val
    },
    SETLANG(state, val) {
      state.lang = val
    },
  },
  actions: {
  },
  getters: {
    isIOS: (state) => {
      return (/iPhone|iPad|iPod/i.test(state.userAgent));
    },
    isAndroid: (state) => {
      return !(/iPhone|iPad|iPod/i.test(state.userAgent));
    },
    getOSType: (state) => {
      if (/iPhone|iPad|iPod/i.test(state.userAgent)) {
        return "iOS";
      } else {
        return "Android";
      }
    }
  },
  modules: {
  }
})
